#opener {
  position: relative;
  height: 75vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-family: 'MedievalSharp', sans-serif;
  background-color: #040114;
}

.opac-box {
  color: #ffffff;
  z-index: 2;
}

#opener-img {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: .8;
  z-index: 1;
}

#home-site-description {
  width: 90%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
}