.popup-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  background-color: rgba(15, 15, 15, 0.3);
  z-index: 150;
}

.delete-popup {
  height: 250px;
  width: 450px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.1);
}

.button-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}