#new-encounter {
  min-height: calc(100vh - 125px);
  width: 100%;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.new-encounter-selections {
  align-items: flex-start;
}

.create-new-char-form-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.36);
  z-index: 52;
}

.create-new-char-form {
  height: 410px;
  width: 600px;
  position: relative;
  padding-top: 50px;
  background-color: #ffffff;
  border-bottom: 3px solid #002d45;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.1);
  z-index: 55;
  overflow-y: auto;
}

.ne-player-table {
  width: 60%;
}

.ne-monster-display {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#search-monsters {
  flex-wrap: nowrap;
  justify-content: center;
}

.ne-monster-table {
  width: 100%;
}

#ne-character-head {
  height: 55px;
  pointer-events: none;
  border: none;
  border-radius: 3px 3px 0 0;
  color: #ffffff;
  background-color: #002d45;
}

.ne-character-row {
  min-height: 50px;
  padding: 5px 12px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 2fr 2fr;
  grid-auto-columns: 1fr;
  align-items: center;
  position: relative;
  text-align: center;
  font-weight: 600;
  border-bottom: 3px solid #166696;
  font-family: 'MedievalSharp', sans-serif;
}

.ne-character-row td, .ne-character-row th {
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-added {
  width: 33%;
}

.new-added-row {
  min-height: 50px;
  padding: 10px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 2fr 2fr;
  grid-auto-columns: 1fr;
  align-items: center;
  position: relative;
  text-align: center;
  font-weight: 600;
  border-bottom: 3px solid #166696;
  font-family: 'MedievalSharp', sans-serif;
}

.new-added-head {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 3px 3px 0 0;
  color: #ffffff;
  background-color: #002d45;
}

.none-added-row {
  height: 50px;
  text-align: center;
  font-weight: 600;
  font-family: 'MedievalSharp', sans-serif;
}

.none-added-row td {
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ne-summary {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.new-campaign-encounter {
  width: 90%;
}

#new-campaign-encounter-head {
  height: 55px;
  pointer-events: none;
  border: none;
  border-radius: 3px 3px 0 0;
  color: #ffffff;
  background-color: #002d45;
}

.new-campaign-encounter-row {
  min-height: 50px;
  padding: 5px 12px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 2fr 2fr;
  grid-auto-columns: 1fr;
  align-items: center;
  position: relative;
  text-align: center;
  font-weight: 600;
  border-bottom: 3px solid #166696;
  font-family: 'MedievalSharp', sans-serif;
}

.new-campaign-encounter-row td, .new-campaign-encounter-row th {
  display: flex;
  align-items: center;
  justify-content: center;
}