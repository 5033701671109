.popout {
  position: fixed;
  top: 0;
  right: 0;
  width: 70%;
  min-height: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 45px 35px 25px;
  background-color: #cde3f1ee;
  border-left: 10px solid #002d45;
  z-index: 8;
}

.splitter {
  height: 3px;
  width: 90%;
  margin: 10px 5% 10px 0;
  background-color: #002d45;
  border-radius: 0 50% 50% 0;
}

.view-specs {
  height: 25px;
  padding: 0 15px;
  position: absolute;
  top: 20px;
  right: 55px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  border-radius: 10px;
  color: #ffffff;
  background-color: #166696;
}

.close-info-button {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 20px;
  top: 20px;
  border-radius: 50%;
  border: 2px solid #040114;
  background-color: inherit;
  cursor: pointer;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.monster-header {
  min-height: 40px;
}

.monster-desc {
  margin-top: 5px;
  width: 100%;
}

.monster-base-info {
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}

.monster-main-skills {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: wrap;
  margin: 5px 0;
}

.monster-sub-skills {
  min-height: 85px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.monster-specials {
  height: fit-content;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.monster-actions {
  height: fit-content;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.monster-legendary-actions {
  height: fit-content;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}