#main-footer {
  height: 50px;
  width: 100%;
  padding: 0 25px 0 15px;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  background-color: #002d45;
}

#copyright-stuff {
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
}

#copyright-stuff > p, #copyright-stuff > h6 {
  margin: 0;
  font-size: .9rem;
}

#social-section {
  display: flex;
  align-items: center;
  gap: 8px;
}

.social-link {
  display: flex;
  align-items: center;
  gap: 5px;
}

.social-img {
  height: 25px;
  border: 1px #ffffff solid;
  border-radius: 50%;
  background-color: #ffffff;
}