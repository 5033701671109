.monster-specs {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  padding: 25px 35px 75px;
}

.splitter {
  height: 5px;
  width: 95%;
  margin: 10px 2.5%;
  background-color: #166696;
  border-radius: 50%;
}

.monster-header {
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.monster-name {
  margin-right: 3px;
}

.monster-desc {
  margin-top: 5px;
  width: 100%;
  text-align: center;
}

.monster-base-info {
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}

.monster-main-skills {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: wrap;
  margin: 5px 0;
}

.monster-sub-skills {
  min-height: 85px;
  padding: 10px 0;
}

.monster-specials {
  height: fit-content;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.monster-actions {
  height: fit-content;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.monster-legendary-actions {
  height: fit-content;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}