.quick-add-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.36);
  z-index: 52;
}

.quick-add {
  height: 400px;
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 50px;
  background-color: #ffffff;
  border-bottom: 3px solid #002d45;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.1);
  z-index: 55;
}

.quick-add > .dashboard-head {
  height: 50px;
  background-color: #002d45;
}

.type-selection {
  height: 90%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.quick-options {
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.quick-add-display {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-y: auto;
}

.quick-add-char {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quick-add-monster {
  min-height: 42px;
  display: grid;
  grid-template-columns: 2fr .5fr .5fr;
  align-items: center;
}