.guide-title {
  width: 100%;
  height: 85px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.guide-body {
  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#guide-filter-wrap {
  width: 92%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #cde3f1e6;
  border-top: 5px solid #166696;
  border-bottom: 2px solid #166696;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.1);
}

#filter-collapse-btn {
  outline: none;
  border: none;
  border-radius: 3px 3px 0 0;
  text-decoration: underline;
  color: #002d45;
  box-shadow: none;
  background: none;
}
#filter-collapse-btn:hover {
  background-color: inherit;
  background: none;
  color: #002d45a0;
}

.guide-filters {
  width: 92%;
  text-align: center;
  position: relative;
  padding: 5px 20px;
  outline: none;
}

.guide-filter-form .col {
  padding: 5px 12px;
}

#form-filter-btns {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.guide-results {
  width: 100%;
  display: table;
}

#guide-result-head {
  height: 55px;
  pointer-events: none;
  font-weight: 700;
  color: #040114;
  border-radius: 3px 3px 0 0;
  background-color: #166696;
  color: #ffffff;
}

.guide-result {
  width: 92%;
  min-height: 50px;
  margin: 0 auto;
  text-align: center;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1.85fr 1.25fr;
  grid-auto-columns: .6fr;
  align-items: center;
  padding: 10px 15px;
  transition: all .5s ease;
  cursor: pointer;
  border-radius: 2px;
  color: #002d45;
  background-color: inherit;
  border-bottom: 2px solid #166696;
  font-family: 'MedievalSharp', sans-serif;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.result-name {
  font-weight: 700;
}

.guide-result:hover {
  width: 96%;
  min-height: 55px;
}