@import url('https://fonts.googleapis.com/css?family=MedievalSharp|Josefin+Sans');

.login-register-page {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  font-family: 'MedievalSharp';
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../../images/login-img.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.login-form-container {
  height: 80%;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.1);
  background-color: #002d45d0;
}

.logo-wrap {
  height: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-decoration: none;
  padding-bottom: 50px;
  color: #ffffff;
}

#login-form {
  height: 100%;
  width: 40%;
  padding: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: #ffffff;
}