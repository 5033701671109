@import "https://fonts.googleapis.com/css?family=MedievalSharp|Josefin+Sans";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, main {
  vertical-align: baseline;
  box-sizing: border-box;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.login-register-page {
  height: 100vh;
  width: 100vw;
  background-image: url("login-img.d9635e49.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
  font-family: MedievalSharp;
  display: flex;
  overflow: hidden;
}

.login-form-container {
  height: 80%;
  width: 80%;
  background-color: #002d45d0;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  box-shadow: 0 2px 10px #00000029, 0 3px 6px #0000001a;
}

.logo-wrap {
  height: 55%;
  color: #fff;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 50px;
  text-decoration: none;
  display: flex;
}

#login-form {
  height: 100%;
  width: 40%;
  color: #fff;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 3%;
  display: flex;
}

.character-blob {
  box-shadow: 0 0 15px #0000001a;
  border: none !important;
  border-bottom: 1px solid #166696 !important;
  border-radius: 3px !important;
}

.character-blob-btns {
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

.quick-add-wrapper {
  width: 100%;
  height: 100%;
  z-index: 52;
  background-color: #0000005c;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.quick-add {
  height: 400px;
  width: 600px;
  z-index: 55;
  background-color: #fff;
  border-bottom: 3px solid #002d45;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  display: flex;
  position: relative;
  box-shadow: 0 2px 10px #00000029, 0 3px 6px #0000001a;
}

.quick-add > .dashboard-head {
  height: 50px;
  background-color: #002d45;
}

.type-selection {
  height: 90%;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

.quick-options {
  height: 70px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.quick-add-display {
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
  overflow-y: auto;
}

.quick-add-char {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.quick-add-monster {
  min-height: 42px;
  grid-template-columns: 2fr .5fr .5fr;
  align-items: center;
  display: grid;
}

.encounter-char {
  text-align: center;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  align-items: center;
  display: grid;
}

.tracker-display {
  width: 90%;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.tracker-table {
  width: 100%;
}

.hidden-columns {
  width: 80%;
}

.hidden-columns td:nth-child(3), .hidden-columns td:nth-child(4), .hidden-columns th:nth-child(3), .hidden-columns th:nth-child(4) {
  display: none;
}

.hidden-columns .tracker {
  grid-template-columns: .65fr 2.5fr .15fr;
}

#tracker-head {
  height: 55px;
  cursor: default;
  color: #fff;
  background-color: #166696;
  border: none;
  border-radius: 3px 3px 0 0;
}

.tracker {
  min-height: 60px;
  text-align: center;
  border-bottom: 3px solid #002d45;
  grid-template-columns: .65fr 2.5fr .75fr .6fr .15fr;
  grid-auto-flow: column;
  align-items: center;
  padding: 5px 12px;
  font-family: MedievalSharp, sans-serif;
  font-weight: 600;
  display: grid;
  position: relative;
  box-shadow: 0 0 15px #0000001a;
}

.inititiative-thing {
  justify-content: center;
  align-items: center;
  display: flex;
}

.tracker-name {
  cursor: pointer;
  justify-content: center;
  gap: 10px;
  display: flex;
}

.info-button {
  width: 20px;
  height: 20px;
  color: #fff;
  cursor: pointer;
  background-color: #166696;
  border: 2px solid #166696;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  font-weight: 500;
  display: flex;
}

.health-display {
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
}

.health-input-wrapper {
  align-items: center;
  gap: 4px;
  display: flex;
}

.health-input::-webkit-outer-spin-button, .health-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#new-encounter {
  min-height: calc(100vh - 125px);
  width: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 0;
  display: flex;
}

.new-encounter-selections {
  align-items: flex-start;
}

.create-new-char-form-wrapper {
  width: 100vw;
  height: 100vh;
  z-index: 52;
  background-color: #0000005c;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.create-new-char-form {
  height: 410px;
  width: 600px;
  z-index: 55;
  background-color: #fff;
  border-bottom: 3px solid #002d45;
  padding-top: 50px;
  position: relative;
  overflow-y: auto;
  box-shadow: 0 2px 10px #00000029, 0 3px 6px #0000001a;
}

.ne-player-table {
  width: 60%;
}

.ne-monster-display {
  width: 60%;
  flex-direction: column;
  align-items: center;
  display: flex;
}

#search-monsters {
  flex-wrap: nowrap;
  justify-content: center;
}

.ne-monster-table {
  width: 100%;
}

#ne-character-head {
  height: 55px;
  pointer-events: none;
  color: #fff;
  background-color: #002d45;
  border: none;
  border-radius: 3px 3px 0 0;
}

.ne-character-row {
  min-height: 50px;
  text-align: center;
  border-bottom: 3px solid #166696;
  grid-template-columns: 2fr 2fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  align-items: center;
  padding: 5px 12px;
  font-family: MedievalSharp, sans-serif;
  font-weight: 600;
  display: grid;
  position: relative;
}

.ne-character-row td, .ne-character-row th {
  justify-content: center;
  align-items: center;
  display: flex;
}

.new-added {
  width: 33%;
}

.new-added-row {
  min-height: 50px;
  text-align: center;
  border-bottom: 3px solid #166696;
  grid-template-columns: 2fr 2fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  align-items: center;
  padding: 10px;
  font-family: MedievalSharp, sans-serif;
  font-weight: 600;
  display: grid;
  position: relative;
}

.new-added-head {
  height: 55px;
  color: #fff;
  background-color: #002d45;
  border: none;
  border-radius: 3px 3px 0 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.none-added-row {
  height: 50px;
  text-align: center;
  font-family: MedievalSharp, sans-serif;
  font-weight: 600;
}

.none-added-row td {
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  display: flex;
}

.ne-summary {
  width: 100%;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.new-campaign-encounter {
  width: 90%;
}

#new-campaign-encounter-head {
  height: 55px;
  pointer-events: none;
  color: #fff;
  background-color: #002d45;
  border: none;
  border-radius: 3px 3px 0 0;
}

.new-campaign-encounter-row {
  min-height: 50px;
  text-align: center;
  border-bottom: 3px solid #166696;
  grid-template-columns: 2fr 2fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  align-items: center;
  padding: 5px 12px;
  font-family: MedievalSharp, sans-serif;
  font-weight: 600;
  display: grid;
  position: relative;
}

.new-campaign-encounter-row td, .new-campaign-encounter-row th {
  justify-content: center;
  align-items: center;
  display: flex;
}

.popup-wrapper {
  height: 100vh;
  width: 100vw;
  z-index: 150;
  background-color: #0f0f0f4d;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
}

.delete-popup {
  height: 250px;
  width: 450px;
  background-color: #fff;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 50px;
  display: flex;
  position: relative;
  box-shadow: 0 2px 10px #00000029, 0 3px 6px #0000001a;
}

.button-wrapper {
  justify-content: space-between;
  gap: 20px;
  display: flex;
}

#my-campaigns {
  min-height: calc(100vh - 125px);
  width: 100vw;
  justify-content: space-evenly;
  padding: 25px;
  display: flex;
}

.campaign-character, .campaign-encounter {
  text-align: center;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  align-items: center;
  display: grid;
}

.monster-specs {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 25px 35px 75px;
  display: flex;
  overflow: auto;
}

.splitter {
  height: 5px;
  width: 95%;
  background-color: #166696;
  border-radius: 50%;
  margin: 10px 2.5%;
}

.monster-header {
  min-height: 40px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.monster-name {
  margin-right: 3px;
}

.monster-desc {
  width: 100%;
  text-align: center;
  margin-top: 5px;
}

.monster-base-info {
  width: 100%;
  justify-content: space-between;
  padding: 10px 0;
  display: flex;
}

.monster-main-skills {
  width: 100%;
  flex-wrap: wrap;
  place-content: space-between;
  align-items: center;
  margin: 5px 0;
  display: flex;
}

.monster-sub-skills {
  min-height: 85px;
  padding: 10px 0;
}

.monster-specials, .monster-actions, .monster-legendary-actions {
  height: -moz-fit-content;
  height: fit-content;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
  display: flex;
}

.popout {
  width: 70%;
  min-height: 100%;
  max-height: 100%;
  z-index: 8;
  background-color: #cde3f1ee;
  border-left: 10px solid #002d45;
  padding: 45px 35px 25px;
  position: fixed;
  top: 0;
  right: 0;
  overflow: auto;
}

.splitter {
  height: 3px;
  width: 90%;
  background-color: #002d45;
  border-radius: 0 50% 50% 0;
  margin: 10px 5% 10px 0;
}

.view-specs {
  height: 25px;
  cursor: pointer;
  color: #fff;
  background-color: #166696;
  border-radius: 10px;
  align-items: center;
  padding: 0 15px;
  text-decoration: none;
  display: flex;
  position: absolute;
  top: 20px;
  right: 55px;
}

.close-info-button {
  width: 25px;
  height: 25px;
  background-color: inherit;
  cursor: pointer;
  border: 2px solid #040114;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  display: flex;
  position: absolute;
  top: 20px;
  right: 20px;
}

.monster-header {
  min-height: 40px;
}

.monster-desc {
  width: 100%;
  margin-top: 5px;
}

.monster-base-info {
  width: 100%;
  justify-content: space-between;
  padding: 10px 0;
  display: flex;
}

.monster-main-skills {
  width: 100%;
  flex-wrap: wrap;
  place-content: space-between;
  align-items: center;
  margin: 5px 0;
  display: flex;
}

.monster-sub-skills {
  min-height: 85px;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
  display: flex;
}

.monster-specials, .monster-actions, .monster-legendary-actions {
  height: -moz-fit-content;
  height: fit-content;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
  display: flex;
}

.guide-title {
  width: 100%;
  height: 85px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.guide-body {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

#guide-filter-wrap {
  width: 92%;
  background-color: #cde3f1e6;
  border-top: 5px solid #166696;
  border-bottom: 2px solid #166696;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
  box-shadow: 0 2px 10px #00000029, 0 3px 6px #0000001a;
}

#filter-collapse-btn {
  color: #002d45;
  box-shadow: none;
  background: none;
  border: none;
  border-radius: 3px 3px 0 0;
  outline: none;
  text-decoration: underline;
}

#filter-collapse-btn:hover {
  background-color: inherit;
  color: #002d45a0;
  background: none;
}

.guide-filters {
  width: 92%;
  text-align: center;
  outline: none;
  padding: 5px 20px;
  position: relative;
}

.guide-filter-form .col {
  padding: 5px 12px;
}

#form-filter-btns {
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

.guide-results {
  width: 100%;
  display: table;
}

#guide-result-head {
  height: 55px;
  pointer-events: none;
  color: #040114;
  color: #fff;
  background-color: #166696;
  border-radius: 3px 3px 0 0;
  font-weight: 700;
}

.guide-result {
  width: 92%;
  min-height: 50px;
  text-align: center;
  cursor: pointer;
  color: #002d45;
  background-color: inherit;
  border-bottom: 2px solid #166696;
  border-radius: 2px;
  grid-template-columns: 1.85fr 1.25fr;
  grid-auto-columns: .6fr;
  grid-auto-flow: column;
  align-items: center;
  margin: 0 auto;
  padding: 10px 15px;
  font-family: MedievalSharp, sans-serif;
  transition: all .5s;
  display: grid;
  box-shadow: 0 0 15px #0000001a;
}

.result-name {
  font-weight: 700;
}

.guide-result:hover {
  width: 96%;
  min-height: 55px;
}

#opener {
  height: 75vh;
  width: 100%;
  background-color: #040114;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-family: MedievalSharp, sans-serif;
  display: flex;
  position: relative;
}

.opac-box {
  color: #fff;
  z-index: 2;
}

#opener-img {
  width: 100%;
  height: 100%;
  opacity: .8;
  z-index: 1;
  position: absolute;
}

#home-site-description {
  width: 90%;
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  display: flex;
}

#main-footer {
  height: 50px;
  width: 100%;
  color: #fff;
  background-color: #002d45;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px 0 15px;
  display: flex;
  position: absolute;
  bottom: 0;
}

#copyright-stuff {
  height: 40px;
  flex-flow: column wrap;
  place-content: flex-start center;
  display: flex;
}

#copyright-stuff > p, #copyright-stuff > h6 {
  margin: 0;
  font-size: .9rem;
}

#social-section {
  align-items: center;
  gap: 8px;
  display: flex;
}

.social-link {
  align-items: center;
  gap: 5px;
  display: flex;
}

.social-img {
  height: 25px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
}

#main-nav {
  width: 100%;
  height: 55px;
  color: #fff;
  padding: 0 25px;
  font-family: MedievalSharp, sans-serif;
}

.main-nav-divs {
  height: 100%;
  align-items: center;
  display: flex;
}

.main-nav-divs > p {
  margin: 0;
}

.nav-links-wrapper {
  height: 100%;
  align-items: center;
  padding: 0 5px;
  display: flex;
}

.nav-links {
  text-align: center;
  color: #fff;
  background: linear-gradient(#0000, #00000026) top / 100% 800%;
  background-color: inherit;
  border-radius: 4px;
  align-items: center;
  padding: 5px 8px;
  text-decoration: none;
  transition: all .3s;
  display: flex;
  box-shadow: none !important;
}

.nav-links-wrapper:has(.nav-links.active) {
  border-top: 5px solid #002d45;
  border-bottom: 5px solid #fff;
}

.nav-links:hover {
  color: #fff;
  background-color: #6a6b6a;
  background-position: bottom;
}

.login-logout {
  height: 30px;
  margin-left: 20px;
  padding: 0 20px;
  font-family: MedievalSharp, sans-serif;
  font-weight: bold;
}

.login-logout:hover {
  background-position: bottom;
}

#stuff-nav {
  height: 40px;
  background-color: #002d45;
  border-top: .5px solid #fff;
  align-items: center;
  gap: 2px;
  padding: 0 15px;
  font-family: MedievalSharp, sans-serif;
  display: flex;
}

.stuff-nav-links-wrapper {
  height: 100%;
  align-items: center;
  padding: 0 3px;
  display: flex;
}

.stuff-nav-links {
  border-radius: 3px;
  padding: 0 6px;
  font-size: .85rem;
}

.stuff-nav-links-wrapper:has(.stuff-nav-links.active) {
  border-top: 3px solid #166696;
  border-bottom: 3px solid #fff;
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: #ffffffb3;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-offset: 16px;
  --toastify-toast-top: max(var(--toastify-toast-offset), env(safe-area-inset-top));
  --toastify-toast-right: max(var(--toastify-toast-offset), env(safe-area-inset-right));
  --toastify-toast-left: max(var(--toastify-toast-offset), env(safe-area-inset-left));
  --toastify-toast-bottom: max(var(--toastify-toast-offset), env(safe-area-inset-bottom));
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-toast-bd-radius: 6px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
  --toastify-color-progress-bgo: .2;
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
}

.Toastify__toast-container--top-left {
  top: var(--toastify-toast-top);
  left: var(--toastify-toast-left);
}

.Toastify__toast-container--top-center {
  top: var(--toastify-toast-top);
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: var(--toastify-toast-top);
  right: var(--toastify-toast-right);
}

.Toastify__toast-container--bottom-left {
  bottom: var(--toastify-toast-bottom);
  left: var(--toastify-toast-left);
}

.Toastify__toast-container--bottom-center {
  bottom: var(--toastify-toast-bottom);
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: var(--toastify-toast-bottom);
  right: var(--toastify-toast-right);
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    left: env(safe-area-inset-left);
    margin: 0;
    padding: 0;
  }

  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: env(safe-area-inset-top);
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: env(safe-area-inset-bottom);
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    right: env(safe-area-inset-right);
    left: initial;
  }
}

.Toastify__toast {
  --y: 0;
  -ms-touch-action: none;
  touch-action: none;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  border-radius: var(--toastify-toast-bd-radius);
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  z-index: 0;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 12px #0000001a;
}

.Toastify__toast--stacked {
  width: 100%;
  transform: translate3d(0, var(--y), 0) scale(var(--s));
  transition: transform .3s;
  position: absolute;
}

.Toastify__toast--stacked[data-collapsed] .Toastify__toast-body, .Toastify__toast--stacked[data-collapsed] .Toastify__close-button {
  transition: opacity .1s;
}

.Toastify__toast--stacked[data-collapsed="false"] {
  overflow: visible;
}

.Toastify__toast--stacked[data-collapsed="true"]:not(:last-child) > * {
  opacity: 0;
}

.Toastify__toast--stacked:after {
  content: "";
  height: calc(var(--g) * 1px);
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
}

.Toastify__toast--stacked[data-pos="top"] {
  top: 0;
}

.Toastify__toast--stacked[data-pos="bot"] {
  bottom: 0;
}

.Toastify__toast--stacked[data-pos="bot"].Toastify__toast--stacked:before {
  transform-origin: top;
}

.Toastify__toast--stacked[data-pos="top"].Toastify__toast--stacked:before {
  transform-origin: bottom;
}

.Toastify__toast--stacked:before {
  content: "";
  height: 100%;
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scaleY(3);
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast--close-on-click {
  cursor: pointer;
}

.Toastify__toast-body {
  flex: auto;
  align-items: center;
  margin: auto 0;
  padding: 6px;
  display: flex;
}

.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1;
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  width: 20px;
  flex-shrink: 0;
  margin-inline-end: 10px;
  display: flex;
}

.Toastify--animate {
  animation-duration: .5s;
  animation-fill-mode: both;
}

.Toastify--animate-icon {
  animation-duration: .3s;
  animation-fill-mode: both;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    border-radius: 0;
    margin-bottom: 0;
  }
}

.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}

.Toastify__toast-theme--light, .Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}

.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}

.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}

.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  z-index: 1;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--light {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  width: 100%;
  height: 100%;
  z-index: var(--toastify-z-index);
  opacity: .7;
  transform-origin: 0;
  border-bottom-left-radius: var(--toastify-toast-bd-radius);
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: linear forwards Toastify__trackProgress;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: 100%;
  border-bottom-left-radius: initial;
  border-bottom-right-radius: var(--toastify-toast-bd-radius);
}

.Toastify__progress-bar--wrp {
  width: 100%;
  height: 5px;
  border-bottom-left-radius: var(--toastify-toast-bd-radius);
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--wrp[data-hidden="true"] {
  opacity: 0;
}

.Toastify__progress-bar--bg {
  opacity: var(--toastify-color-progress-bgo);
  width: 100%;
  height: 100%;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  border-radius: 100%;
  animation: .65s linear infinite Toastify__spin;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, var(--y), 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, var(--y), 0);
  }
}

@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, var(--y), 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, var(--y), 0);
  }
}

@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, calc(var(--y)  - 10px), 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, calc(var(--y)  + 20px), 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, calc(var(--y)  - 10px), 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, calc(var(--y)  + 20px), 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: translate3d(0, var(--y), 0) scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: translate3d(0, var(--y), 0) perspective(400px);
  }

  30% {
    transform: translate3d(0, var(--y), 0) perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }

  to {
    transform: translate3d(0, var(--y), 0) perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translate3d(0, var(--y), 0);
  }
}

@keyframes Toastify__slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translate3d(0, var(--y), 0);
  }
}

@keyframes Toastify__slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translate3d(0, var(--y), 0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translate3d(0, var(--y), 0);
  }
}

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, var(--y), 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, var(--y), 0);
  }
}

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, var(--y), 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, var(--y), 0);
  }
}

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, var(--y), 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, var(--y), 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
  animation-duration: .3s;
  animation-timing-function: ease-in;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
  animation-duration: .3s;
  animation-timing-function: ease-in;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
  animation-duration: .3s;
  animation-timing-function: ease-in;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
  animation-duration: .3s;
  animation-timing-function: ease-in;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=index.382eb8a7.css.map */
