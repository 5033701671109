#main-nav {
  width: 100%;
  height: 55px;
  padding: 0 25px;
  color: #ffffff;
  font-family: 'MedievalSharp', sans-serif;
}

.main-nav-divs {
  height: 100%;
  display: flex;
  align-items: center;
}

.main-nav-divs > p {
  margin: 0;
}

.nav-links-wrapper {
  height: 100%;
  padding: 0 5px;
  display: flex;
  align-items: center;
}

.nav-links {
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 5px 8px;
  border-radius: 4px;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s ease all;
  box-shadow: none !important;
  background: linear-gradient(#0000, rgb(0 0 0/15%)) top/100% 800%;
  color: #ffffff;
  background-color: inherit;
}

.nav-links-wrapper:has(.nav-links.active) {
  border-bottom: #ffffff 5px solid;
  border-top: #002d45 5px solid;
}

.nav-links:hover {
  background-position: bottom;
  background-color: #6a6b6a;
  color: #ffffff;
}

.login-logout {
  height: 30px;
  padding: 0 20px;
  font-weight: bold;
  font-family: 'MedievalSharp', sans-serif;
  margin-left: 20px;
}
.login-logout:hover {
  background-position: bottom;
}


#stuff-nav {
  height: 40px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  gap: 2px;
  border-top: #ffffff .5px solid;
  background-color: #002d45;
  font-family: 'MedievalSharp', sans-serif;
}

.stuff-nav-links-wrapper {
  height: 100%;
  padding: 0 3px;
  display: flex;
  align-items: center;
}

.stuff-nav-links {
  font-size: .85rem;
  padding: 0 6px;
  border-radius: 3px;
}

.stuff-nav-links-wrapper:has(.stuff-nav-links.active) {
  border-bottom: #ffffff 3px solid;
  border-top: #166696 3px solid;
}