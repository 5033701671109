#my-campaigns {
  min-height: calc(100vh - 125px);
  width: 100vw;
  padding: 25px;
  display: flex;
  justify-content: space-evenly;
}

.campaign-character {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  text-align: center;
  align-items: center;
}

.campaign-encounter {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  text-align: center;
  align-items: center;
}