.character-blob {
  border-radius: 3px !important;
  border: none !important;
  border-bottom: 1px solid #166696 !important;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.character-blob-btns {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}